:root {
  --font-min: 10px;
  --font-smallest: 11px;
  --font-small: 12px;
  --font-regular: 14px;
  --font-medium: 15px;
  --font-default: 16px;
  --font-large: 18px;
  --font-large-medium: 22px;
  --font-larger: 28px;
  --font-largest: 32px;
  --font-title: 38px;
  --font-max: 40px;

  --default-font-color: #061938;
  --white-font-color: #fff;
  --backgroundWhite: #fff;

  --primary-color: #0054a6;
  --secondary-color: #1565c0;
  --third-color: #fcaf17;
  --default-color: #426fa1;
  --progress-bar-background: #fcaf17;

  --gray-darkest: #25282b;
  --gray-darker: #657787;
  --dark-gray: #444444;
  --regular-gray: #555555;
  --medium-gray: #7d8073;
  --default-gray: #d9d9d9;
  --light-gray: #e1e3e6;

  --primary-color-hover: #46005e;
  --secondary-color-hover: #fcaf17;
}

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
}

button * {
  pointer-events: none;
}
button:disabled {
  cursor: not-allowed;
}

.content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.ReactModal__Content {
  width: fit-content;
}

@media (max-width: 680px) {
  .ReactModal__Content {
    width: 308px;
    height: 264px;
  }
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

div.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar {
  width: 4px;
}

div.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
  width: 4px;
  background: #bcbcbc;
  border: 0.5rem;
  border-radius: 0.5rem;
}

div.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-track {
  width: 4px;
  background: #f5f5f5;
  border-radius: 0.5rem;
}

.react-modal-content {
  outline: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 16px solid #bcbcbc;
  border-radius: 50%;
  border-top: 16px solid #0054a6;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
